<template>
  <div class="container o-text-image">
    <div class="row">
      <div class="col-lg-4">
        <div class="o-text-image__background">
          <div class="o-text-image__content">
            <component
              :is="isFirst ? 'h1' : 'h2'"
              v-if="$isNotNullOrUndefined(content.headline)"
              class="o-text-image__headline"
            >
              {{ content.headline }}
            </component>
            <div
              v-if="$isNotNullOrUndefined(content.rteText)"
              class="o-text-image__text"
              v-html="content.rteText"
            />
            <base-link
              v-if="$isNotNullOrUndefined(content.link)"
              class="o-text-image__link"
              :link="content.link.url"
              :target="content.link.target"
            >
              {{ content.link.name }}
              <base-icon icon="chevron-right" />
            </base-link>
          </div>
        </div>
      </div>
      <div class="col-lg-8 d-none d-lg-block">
        <div class="o-text-image__figure">
          <base-image
            v-if="$isNotNullOrUndefined(content.image) && content.image.length > 0 && $isNotNullOrUndefined(content.image[0].url)"
            :url="content.image[0].url"
            :width="909"
            :height="390"
            :ratio="909/390"
            :alt-text="content.imageAltText"
            image-class="o-text-image__image"
          />
          <skeleton-loader
            v-else
            :aspect-ratio="909/390"
          />
        </div>
      </div>
    </div>
    <breadcrumb v-if="isFirst && !isThemed" class="-content" />
  </div>
</template>

<script>
import contentMixin from '@/mixins/contentMixin';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import BaseLink from '@/components/atoms/BaseLink';
import BaseImage from '@/components/atoms/BaseImage';
import BaseIcon from '@/components/atoms/BaseIcon';
import SkeletonLoader from '@/components/atoms/SkeletonLoader';

export default {
  name: 'TextImage',
  components: {
    BaseLink,
    BaseImage,
    BaseIcon,
    Breadcrumb,
    SkeletonLoader
  },
  mixins: [contentMixin],
  props: {
    isFirst: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isThemed () {
      return !!this.$store.getters['content/content/getTheme'];
    }
  }
};
</script>
