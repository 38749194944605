<template>
  <div v-if="breadcrumb.length > 0" class="m-breadcrumb" v-bind="$attrs">
    <nav class="m-breadcrumb__nav">
      <base-link
        v-for="(breadcrumbItem, index) in breadcrumb"
        :key="index"
        class="m-breadcrumb__link"
        :link="breadcrumbItem.link.url"
      >
        <span class="m-breadcrumb__item-text">{{ breadcrumbItem.link.name }}</span>
      </base-link>
      <base-link
        v-if="isBrandPage"
        class="m-breadcrumb__link"
      >
        <span class="m-breadcrumb__item-text">{{ pageTitle }}</span>
      </base-link>
    </nav>
  </div>
</template>

<script>
import BaseLink from '@/components/atoms/BaseLink.vue';

export default {
  name: 'Breadcrumb',
  components: {
    BaseLink
  },
  props: {
    product: {
      type: Object,
      default: null
    }
  },
  computed: {
    navigationItems () {
      return this.$store.getters['navigation/getNavigationItems'];
    },
    id () {
      return this.isProductPage
        ? this.product.primaryCategoryId
        : this.$store.getters['content/content/getContent']?.meta?.id;
    },
    type () {
      return this.$store.getters['content/content/getContent']?.meta?.type;
    },
    isProductPage () {
      return this.type === 'ContentProduct' && !!this.product;
    },
    isBrandPage () {
      return this.type === 'brandPage';
    },
    pageTitle () {
      return this.$store.getters['content/content/getContent']?.meta?.title;
    },
    breadcrumb () {
      let breadcrumb = [];
      let path = this.find(this.navigationItems, this.id);
      if (path !== null) {
        let frontPage = [
          {
            link: {
              url: '/',
              name: 'Forside'
            }
          }
        ];
        breadcrumb = frontPage.concat(path);
      }
      return breadcrumb;
    },
    jsonLd () {
      if (!this.breadcrumb.length) return null;

      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': this.breadcrumb.map((item, index) => ({
          '@type': 'ListItem',
          'position': index + 1,
          'name': item.link.name,
          'item': `https://www.bronnum.dk${item.link.url}`
        }))
      };
    }
  },
  methods: {
    find (array, id) {
      if (typeof array !== 'undefined' && array) {
        for (let i = 0; i < array.length; i++) {
          if (array[i].id == id) return [array[i]];
          let a = this.find(array[i].children, id);
          if (a != null) {
            a.unshift(array[i]);
            return a;
          }
        }
      }
      return null;
    }
  },
  head () {
    return this.jsonLd
      ? {
          script: [
            {
              hid: 'json-ld-breadcrumb', // Unique ID to prevent duplicate entries
              type: 'application/ld+json',
              json: this.jsonLd // Vue Meta handles JSON serialization properly
            }
          ]
        }
      : {};
  }
};
</script>

